import '@css/app.css';                                       // Styles CSS
import '@css/less.less';                                     // Styles LESS
import '@css/scss.scss';                                     // Styles SCSS / SASS
import 'jquery';
import ReactDOM from 'react-dom';
import {createElement} from 'react';
import Footer from '@/js/components/Footer.jsx';

document.addEventListener('DOMContentLoaded', () => {

    debug.store('DOM Content Loaded (from React)');

    const footer = document.getElementById('footer');

    if (!!footer) {
        ReactDOM.render(createElement(Footer, { version: process.env.NODE_ENV !== 'development' ? process.env.VERSION : 'DEVELOPMENT BUILD' }), footer);
    }

});
