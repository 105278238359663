import React, {Component} from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        //region Links
        const links = <div>
            <a href="/docs/PaymentSteps.docx">Процесс оплаты</a><br/>
            <a href="/docs/Offer.rtf">Условия возврата и обмена</a><br/>
            <a href="/docs/PrivacyPolicy.docx">Политика обработки персональных данных</a><br/>
        </div>;
        //endregion

        //region Requisites
        const requisitesAltay = <div>
            <b>Реквизиты:</b><br/>
            ОАО &laquo;Алтайкрайгазсервис&raquo;<br/>
            ИНН/КПП: 2221024324/222101001<br/>
            Юридический адрес: 656037, Алтайский край, г. Барнаул, ул. Северо-Западная, д.20<br/>
            Тел./факс: (3852) 50-54-65<br/>
        </div>;

        const requisitesSibgaz = <div>
            <b>Реквизиты:</b><br/>
            ОАО &laquo;Сибгаз&raquo;<br/>
            ИНН/КПП: ???/???<br/>
            Юридический адрес: address <br/>
            Тел./факс: valid phone<br/>
        </div>;

        const requisites = process.env.HOST === 'altay' ? requisitesAltay : requisitesSibgaz;
        //endregion

        //region Contacts
        const contactsAltay = <div>
            <b>Контакты:</b><br/>
            Фактический адрес: 656037, Алтайский край, г. Барнаул, ул. Северо-Западная, д.20<br/>
            Электронная почта: kraygas@akgs.ru<br/>
            Телефоны: (3852) 50-54-65, (3852) 50-54-77<br/><br/>
            <div>By <a href="https://dec04-pf.herokuapp.com/">dec04</a>. Версия {this.props.version}</div>
        </div>;

        const contactsSibgaz = <div>
            <b>Контакты:</b><br/>
            Фактический адрес: address <br/>
            Электронная почта: valid@email.com<br/>
            Телефоны: valid phones<br/><br/>
            <div>By <a href="https://dec04-pf.herokuapp.com/">dec04</a>. Версия {this.props.version}</div>
        </div>;

        const contacts = process.env.HOST === 'altay' ? contactsAltay : contactsSibgaz;
        //endregion

        return (
            <div className="footer">
                <div className="footer-requisites">
                    {links}
                    {requisites}
                    {contacts}
                </div>
                <div><b>Warning! Test release for new API!</b></div>
            </div>
        );
    }
}

export default Footer;
